export const API = {
    // Production
    BASE_URL: 'https://api.xpansecafe.com/',
    SOCKET_URL: 'https://api.xpansecafe.com/',

    // Development
    // BASE_URL: 'https://xpanse.dev.bexcart.com/',
    // SOCKET_URL: 'https://xpanse.dev.bexcart.com/',
    
    // branch_key: "key_live_oueMKfCjWlfxNXM5yYy0wppmxsaVXKIC", //production
    Branch_key: "key_test_jthIIltdWnnxR0S4u9CYqhdczFfO5NSG", //test
}